import React, { Fragment, useEffect, useState } from "react"
import { Link } from "gatsby"
import axios from "axios"

export default function StatusSidebar() {
  const [knownWords, setKnownWords] = useState(975)
  useEffect(() => {
    const fetch = async () => {
      const res = await axios.get(
        "https://spreadsheets.google.com/feeds/cells/1i8UOcKY1Eqzs20rHGtmP9eNiwbmJgmyzjvchDMnk6fc/1/public/basic?alt=json"
      )
      const charactersLearned = []
      res.data.feed.entry.forEach(array => {
        let cellLocation = array.title.$t
        let cellValue = array.content.$t

        if (cellLocation[0] === "C" && cellLocation !== "C1") {
          charactersLearned.push(cellValue)
        }
      })
      setKnownWords(charactersLearned[charactersLearned.length - 1])
    }
    fetch()
  }, [])
  return (
    <Fragment>
      <Link
        className="hidden"
        to="blog/im-going-to-learn-3000-chinese-characters-in-120-days"
      >
        <div className="hover-up max-w-xs border-8 border-l-0 border-yellow-500 p-4 pb-6 text-white shadow-2xl hidden xl:flex flex-col items-center fixed left-0 bg-red-700 rounded-lg rounded-l-none">
          <h3 className="text-xl uppercase tracking-widest font-semibold">
            Current Goal
          </h3>
          <h4 className="text-center">
            Learn 3,000 <span className="text-yellow-400">Chinese 中文 </span>{" "}
            characters by March 31, 2020.
          </h4>
          {/* <p>I'm {Math.floor((knownWords / 3000) * 100)}% of the way there</p>{" "} */}
          <p>
            <span className="underline">Read how I'm doing it.</span>
          </p>
        </div>
        <div className="border-8 border-yellow-500 hover-up xl:hidden pt-2 pb-4 px-1 flex flex-col items-center rounded-lg bg-red-700 text-white shadow-lg">
          <h3 className="text-xl uppercase tracking-widest font-semibold">
            Current Goal
          </h3>
          <h4 className="text-center">
            Learn 3,000 <span className="text-yellow-400">Chinese 中文 </span>{" "}
            characters by March 31, 2020.
          </h4>
          {/* <p>I'm {Math.floor((knownWords / 3000) * 100)}% of the way there</p> */}

          <p>
            <span className="underline">Read how I'm doing it.</span>
          </p>
        </div>
      </Link>
    </Fragment>
  )
}
